<template>
	<BaseCard
			:title="data.title"
			:data-content-id="data._id"
			:data-content-key="data.key"
	>
		<PortableText
				:components="components"
				:value="data.body"
		/>
	</BaseCard>
</template>
<script setup lang="ts">
	import {PortableText} from '@portabletext/vue';
	import type {PropType} from 'vue';
	import BaseCard from '~/components/generic/BaseCard.vue';
	import type {SanityHelpPage} from '~/utils/helpPagesHelper';
	import SanityYoutubeEmbed from '~/components/html-pages/SanityYoutubeEmbed.vue';

	defineProps({
		data: {
			type: Object as PropType<SanityHelpPage>,
			required: true
		}
	});

	const components = {
		types: {
			youtube: SanityYoutubeEmbed
		}
	};
</script>

<style scoped>
	:deep(.card-body) {
		& * {
			margin: calc(var(--line-height-p-2) * 1em) 0 0 0;
		}

		& h1 + *,
		& h2 + *,
		& h3 + *,
		& p + ul,
		& p + ol,
		& *:first-child,
		& li {
			margin-top: 0;
		}

		ul, ol {
			padding-left: 1.2em;
		}

		p:empty {
			display: none;
		}

		h1, h2 {
			font-size: var(--font-size-p-4);
			line-height: var(--line-height-p-4);
			margin-top: calc(var(--line-height-p-4) * 1em);
		}

		h3 {
			font-size: var(--font-size-p-3);
			line-height: var(--line-height-p-3);
			margin-top: calc(var(--line-height-p-3) * 1em);
		}
	}
</style>
